/* @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@600&family=Inter:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@600&family=Inter:wght@300;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@600&family=Inter:wght@200;300;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600&family=Inter:wght@200;300;800;900&display=swap');
:root {
  --colorMain: #a9adffa3;
  --transition: 0.3s;
}


a {
  color: black;
}
body {
  font-family: sans-serif;
}

.App {
  min-height: 100vh;
}

.top-bar {
  position: sticky;
  top: 0;
  height: 50px;
  background-color: #ffffffeb;
  text-align: center;
  box-shadow: inset 0px -79px 56px -100px black;
  z-index: 10;
}
.top-bar-container {
  max-width: 1024px;
  margin: auto;
}

.logo {
  width: 40px;
  float: left;
  margin: 5px;
}


.title {
  /* background-image: linear-gradient(90deg, black, white); */
  font-family: 'Inter', sans-serif;
    background: linear-gradient(
      to right, 

      rgb(255, 153, 0), 
      rgb(153, 71, 173) 90%
      /* black 90% */
    );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: color-transition 3s ease-in-out infinite alternate;
}
@keyframes color-transition {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}
.github-icon {
  vertical-align: -1px;
  margin-right: 2px;
}

button {
  padding: 10px;
  margin: 5px 2vw;
  background-color: #00000000;
  border: none;
  border-radius: 5px;
  color: #000000;
    font-family: 'Inconsolata', monospace;
  transition: var(--transition);
  font-size:medium;
}
button:hover {
  background-color: var(--colorMain);
}

.info {
  height: 480px;
  text-align: center;
  background-image: linear-gradient(to bottom, #ffffff, var(--colorMain));
}
.info h1 {
  margin-top: 100px;
  font-size: 80px;
  display: inline-block;
}
.info p {
  margin-top: 40px;
  margin-bottom: 60px;
  font-size: 24px;
  font-family: 'Inter', sans-serif;
}

.carousel-container {
  position: relative;
  max-width: 1224px;
  height: 500px;
  margin: 150px auto 250px;
}
.carousel {
  overflow: hidden;
  font-weight: bold;
  font-size: 24px;
}
.slideshow-arrow {
  position: absolute;
  top: 200px;
  width: 70px;
  background-color: #80808000;
  border-radius: 20px;
  transition: var(--transition);
}
.slideshow-arrow:hover {
  background-color: var(--colorMain);
}
#rightArrow {
  right: 0px;
  left: unset;
}
.slideshow-arrow svg, .slideshow-arrow path {
  width: 100%;
  height: 100%;
  fill: #00000070;
}

.carousel-items-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100000px;
}
.carousel-item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1224px;
  color: black;
  text-align: center;
}

#team {
  margin-top: 100px;
  text-align: center;
}
.member-card {
  display: inline-block;
  text-align: center;
  padding: 20px;
  margin: 0 10px;
  border-radius: 10px;
  box-shadow: 0 0 6px #0000002e;
  transition: 0.5s;
  font-family: 'Inconsolata', monospace;
  font-size: 16px;
  font-weight: 400;
  
  
}
.member-card:hover {
  box-shadow: 0 0 10px #00000047, inset 0px -149px 174px -100px var(--colorMain);
}
.member-image {
  width: 150px;
  border-radius: 1000px;
}
.member-card p {
  margin: 5px 0;
}

.footer {
  height: 150px;
  margin-top: 100px;
  background-color: #e5e5e5;
  text-align: center;
}
.logo-wrapper {
  text-align: left;
}
.logo-wrapper img {
  float: unset;
}


.demo-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
/* .democard-container{
  display:flex;
  flex-direction: row;
  height:100%;
 
} */


.democard-container {
  display: flex;
  /* set the container to display flex */
  flex-direction: row;
  /* set the flex direction to column */
  align-items: center;
  /* center horizontally */
  width: 1200px;
  /* set the width of the container */
  margin: 20px;
  /* add some margin */
  justify-content: center;
  gap: 80px;
}

#item1 {
  flex-direction: row-reverse;
}

.container-column {
  width: 50%;
}
.column1 {
      flex: 5 1;
        height: 350px;
        box-shadow: 0px 0px 15px grey;
        border-radius: 10px;
        overflow: hidden;

}
.column2 {
  flex:1;
}
.column3 {
  flex:5;
}

.line {
  border-right: dotted 1px black;
  height:500px;
}

.container-column img {
  position: relative;
  /* position the image absolutely inside the container */
  top: 0;
  left: 0;
  width: 100%;
  /* set the width of the image to 100% of the container */
  /* height: 100%; */
  /* set the height of the image to 100% of the container */
  object-fit: cover;
  /* make sure the image covers the entire container */
}

.democard-description {
  display: inline-flex;
  /* set the description container to display flex */
  flex-direction: column;
  /* set the flex direction to column */
  align-items: center;
  /* center horizontally */
  padding: 10px;
  /* add some padding */
  font-family: 'Inconsolata', monospace;
  font-size:18px;
  font-weight: bold;
}
